// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// ie fix
html body .app.flex-row.align-items-center {
  height: 100vh;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}
.sidebar .nav-link i {
  color: #99ca93;
}
.sidebar .nav-link.active .nav-icon {
  color: #99ca93;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: #1e2225;
}
.sidebar .nav-link.active {
  color: #fff;
  background: #1e2225;
}
.modal {
  background: rgba(0, 0, 0, 0.3);
}
#loading .modal-content {
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: white;
}
::-webkit-scrollbar {
  // display: none;
  width: 0px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  height: 10px;
}
.divider {
  border-bottom: 1px dashed #8d9093;
  padding: 10px;
}
.btn-ghost-back {
  color: #1e2225;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
  font-weight: bold;
}
.dividerTitle {
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2) !important;
  margin-bottom: 10px;
  padding: 2px;
}

.btnMultiSelect {
  background-color: #a7afb4;
  color: #000;
  cursor: pointer;
  border: 1px solid transparent;
  width: 42px;
  height: 42px;
  line-height: 2.8;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
}
.labelMultiSelect {
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btnMultiSelectSelected {
  background-color: #db7537;
  color: white;
}
.center {
  margin: auto;
}
.cui-chevron-right {
  color: #6f7487;
  float: "left";
}
.cui-chevron-right:hover {
  color: #fff;
}
.cui-chevron-left {
  color: #6f7487;
  float: "right";
}
.cui-chevron-left:hover {
  color: #fff;
}
.displayedTime {
  // font-weight: 500;
  // line-height: 2.2;
  // font-size: 30px;
}
.visualizer {
  width: 100%;
  height: 43px;
  border-radius: 5px;
  float: right;
}
.recButton {
  color: #fb5615;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
  font-weight: bold;
}
.recButton:hover {
  color: #ce4712;
  background-color: transparent;
  border-color: transparent;
}
.saveButton {
  color: #6f7487;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
  font-weight: bold;
}
.saveButton:hover {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}
.recContainer {
  box-shadow: 2px 2px 2px 2px rgba(39, 64, 96, 0.3);
  background-color: #274060;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  margin: 0 0 20px 0;
}
.main {
  background-color: #01003b;
  color: white;
}
.react-h5-audio-player > .flex {
  background-color: #274060 !important;
  box-shadow: 2px 2px 2px 2px rgba(39, 64, 96, 0.3) !important;
  border-radius: 5px;
}
.questionFooter {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  left: 0;
  right: 0;
  font-weight: 500;
  line-height: 2.2;
  font-size: 20px;
}

.drawerBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.drawerRight {
  position: fixed;
  right: 0;
  top: 0;
  width: 33%;
  bottom: 0;
  z-index: 1001;
  overflow: auto;
  background: rgba(255, 255, 255, 0.9);
}
.drawerLeft {
  position: fixed;
  left: 0;
  top: 0;
  width: 33%;
  bottom: 0;
  z-index: 1001;
  overflow: auto;
  background: rgba(255, 255, 255, 0.9);
}
.questionlist {
  background-color: #eee;
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px;
}
.question {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: 2px;
  text-align: center;
  width: 70px;
}
.questionSelected {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin: 2px;
  text-align: center;
  background-color: #db7537;
  color: #fff;
  width: 70px;
}
